import React, { useRef, useState, useEffect } from "react";
import styled from "styled-components";

import ImgsViewer from "react-images-viewer";

function ImageCarousel({ images }) {
  const [isCarouselOpen, setCarousel] = useState(false);
  const [currImgIndex, setCurrImgIndex] = useState(0);
  const parentThumbnailRef = useRef(null);
  const [parentThumbnailWidth, setParentThumbnailWidth] = useState(0);

  useEffect(() => {
    const updateParentWidth = () => {
      if (parentThumbnailRef.current) {
        const width = parentThumbnailRef.current.offsetWidth;
        setParentThumbnailWidth(width);
      }
    };

    updateParentWidth();
    window.addEventListener("resize", updateParentWidth);
    return () => {
      window.removeEventListener("resize", updateParentWidth);
    };
  }, []);

  const onClickNextArrow = (e) => {
    setCurrImgIndex((currImgIndex + 1) % images.length);
  };

  const onClickPrevArrow = (e) => {
    setCurrImgIndex((currImgIndex - 1 + images.length) % images.length);
  };

  const onClickFromMiniThumbnail = (imgIndex) => {
    setCurrImgIndex(imgIndex);
  };

  const onOpen = (e) => {
    setCarousel(true);
  };

  const onClose = (e) => {
    setCarousel(false);
  };

  return (
    <>
      <ThumbnailWrapper>
        <Thumbnail
          src={images[currImgIndex]}
          alt={"flooring iamge"}
          onClick={onOpen}
          ref={parentThumbnailRef}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <MiniThumbnailCarousel
            style={{ width: `${parentThumbnailWidth * 0.8}px` }}
          >
            {images.map((image, index) => {
              if (index !== currImgIndex) {
                return (
                  <img
                    src={image}
                    alt={`flooring-${index}`}
                    key={`image-number${index}`}
                    style={{
                      width: "4rem",
                      cursor: "pointer",
                      objectFit: "contain",
                    }}
                    onClick={() => {
                      onClickFromMiniThumbnail(index);
                    }}
                  />
                );
              }
            })}
          </MiniThumbnailCarousel>
        </div>
      </ThumbnailWrapper>
      <ImgsViewer
        imgs={images.map((img) => {
          return { src: img };
        })}
        isOpen={isCarouselOpen}
        currImg={currImgIndex}
        onClose={onClose}
        preloadNextImg={true}
        showImgCount={true}
        onClickPrev={onClickPrevArrow}
        onClickNext={onClickNextArrow}
        width={500}
        backdropCloseable={true}
      />
    </>
  );
}

const ThumbnailWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 1rem;
  max-width: 300px;
  min-width: 250px;
`;

const Thumbnail = styled.img`
  cursor: pointer;
`;

const MiniThumbnailCarousel = styled.div`
  display: flex;
  flex-direction: row;
  overflow: auto;
  justify-content: space-betweeen;

  gap: 0.5rem;
`;

export default ImageCarousel;
